import { useFormik } from 'formik';
import Link from 'next/link';
import * as Yup from 'yup';
import { FormErrorMessage } from '@/shared/components/features/form/FormErrorMessage';
import { FormSubmitButtons } from '@/shared/components/features/form/FormSubmitButtons';
import { FormText } from '@/shared/components/features/form/FormText';
import { MainContainer } from '@/shared/components/parts/MainContainer';
import { Spacer } from '@/shared/components/parts/Spacer';
import { generateFormikInputFieldOptions } from '@/shared/helpers/form/generateFieldOptions';
import { getFormikErrorMessage } from '@/shared/helpers/form/getFormikErrorMessage';
import { placeholderTexts } from '@/shared/helpers/form/placeholderTexts';
import { yupValidations } from '@/shared/helpers/form/yupValidations';
import type { UseLoginForm } from '../index.hook';

export const InputForm: React.FC<{
  state: UseLoginForm['state'];
  invokeLoginChallenge: UseLoginForm['invokeLoginChallenge'];
  isLoading: boolean;
}> = ({ state, invokeLoginChallenge, isLoading }) => {
  const formik = useFormik({
    initialValues: state.formValues,
    validationSchema: Yup.object().shape({
      loginId: yupValidations.loginId,
      password: yupValidations.password,
    }),
    onSubmit: invokeLoginChallenge,
  });

  return (
    <MainContainer>
      <Spacer level={3} />
      <FormErrorMessage message={state.errorMessage} />
      <div className="tw-text-h2 tw-text-main-100">ログイン</div>
      <Spacer level={2} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormText
          {...generateFormikInputFieldOptions(formik, 'loginId', 'text')}
          autoComplete="username"
          label={placeholderTexts.loginId.label}
          placeholder={placeholderTexts.loginId.placeholder}
          validationMessage={getFormikErrorMessage(formik, 'loginId')}
        />
        <Spacer level={1} />
        <FormText
          {...generateFormikInputFieldOptions(formik, 'password', 'password')}
          autoComplete="new-password"
          label={placeholderTexts.password.label}
          placeholder={placeholderTexts.password.placeholder}
          validationMessage={getFormikErrorMessage(formik, 'password')}
        />
        <Spacer level={1} />
        <Link
          href="/reset-password"
          passHref
          className="tw-text-base2 tw-underline"
        >
          パスワードを忘れた方
        </Link>
        <Spacer level={3} />
        <FormSubmitButtons
          buttons={[
            {
              buttonType: 'button',
              text: 'ログイン',
              type: 'submit',
              disabled: isLoading,
              isLoading,
            },
            {
              buttonType: 'link',
              text: 'アカウントを無料で登録',
              buttonStyle: 'secondary',
              href: '/signup',
            },
          ]}
        />
      </form>
      <Spacer level={4} />
    </MainContainer>
  );
};
