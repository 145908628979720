import Link from 'next/link';
import { MainContainer } from '../MainContainer';
import { Spacer } from '../Spacer';

export const NoticePage: React.FC<{
  title: string;
  texts: string | string[];
  links: {
    href: string;
    text: string;
  }[];
}> = ({ title = null, texts = null, links }) => (
  <MainContainer>
    <Spacer level={3} />
    <div className="tw-grid tw-grid-cols-1 tw-gap-[1rem]">
      <div className="tw-text-h3 tw-text-main-100 tw-halt">{title}</div>
      <div className="tw-text-main-100">
        {texts instanceof Array ? (
          <div>
            {texts.map((val) => (
              <div key={val}>{val}</div>
            ))}
          </div>
        ) : (
          <div>{texts}</div>
        )}
      </div>
    </div>
    <Spacer level={3} />
    <div>
      {links.map(({ href, text }) => (
        <Link key={href} href={href} passHref className="tw-underline">
          <div className="tw-text-base1Title">{text}</div>
        </Link>
      ))}
    </div>
    <Spacer level={4} />
  </MainContainer>
);
