import type { FormikProps } from 'formik';

export function getFormikErrorMessage<T, U extends keyof T>(
  formik: FormikProps<T>,
  name: U
): string {
  return formik.touched[name] && formik.errors[name]
    ? (formik.errors[name] as string)
    : '';
}
