import type { FormikProps } from 'formik';

export function generateFormikInputFieldOptions<T, K extends keyof T>(
  formik: FormikProps<T>,
  name: K,
  type: 'text' | 'password' | 'tel' | 'email' | 'checkbox' | 'radio'
): React.InputHTMLAttributes<HTMLInputElement> {
  return {
    type,
    name: name as string,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    value: formik.values[name] as unknown as string,
  };
}

export function generateFormikSelectFieldOptions<T, K extends keyof T>(
  formik: FormikProps<T>,
  name: K
): React.SelectHTMLAttributes<HTMLSelectElement> {
  return {
    name: name as string,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    value: formik.values[name] as unknown as string,
  };
}
