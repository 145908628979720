import React from 'react';
import { Spacer } from '../../../parts/Spacer';
import { FormValidationMessage } from '../FormValidationMessage';
import VisibilityIcon from './visibility_black_24dp.svg';
import VisibilityOffIcon from './visibility_off_black_24dp.svg';

export const FormText: React.FC<
  {
    label: string;
    validationMessage?: string | undefined;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ label, validationMessage, ...attr }) => {
  const isPassword = attr.type === 'password';
  const [isRevealPassword, setIsRevealPassword] =
    React.useState<boolean>(false);

  return (
    <div>
      <div className="tw-relative">
        <div
          className={
            validationMessage
              ? 'tw-absolute tw-inset-0 tw-rounded-4 tw-bg-caution-lv1 tw-opacity-20'
              : 'tw-absolute tw-inset-0 tw-rounded-4 tw-bg-base-lv1'
          }
        />

        <input
          {...attr}
          placeholder={attr?.placeholder ?? ' '}
          type={
            isPassword && isRevealPassword ? 'text' : (attr?.type ?? 'text')
          }
          className="tw-peer tw-relative tw-block tw-h-[3.5rem] tw-w-full tw-appearance-none tw-rounded-4 tw-border-[2px] tw-border-main-60 tw-bg-[transparent] tw-p-[0.5rem] tw-pt-[1.25rem] tw-text-main-100 tw-outline-none placeholder:tw-font-normal placeholder:tw-text-main-40 placeholder:tw-opacity-0 placeholder:tw-transition-opacity placeholder:tw-ease-linear focus:placeholder:tw-opacity-100"
        />
        <label className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-[0.5rem] tw-right-[2rem] tw-my-auto tw-block tw-h-[1em] tw-origin-[0] tw-truncate tw-text-base2 tw-leading-[1] tw-text-main-60 tw-transition-transform peer-focus:tw-translate-y-[-1rem] peer-focus:tw-scale-[85%] peer-focus:tw-text-main-60 peer-[:not(:placeholder-shown)]:tw-translate-y-[-1rem] peer-[:not(:placeholder-shown)]:tw-scale-[85%] peer-[:not(:placeholder-shown)]:tw-text-main-60">
          {label}
        </label>
        {isPassword && (
          <button
            className="tw-absolute tw-inset-y-0 tw-right-[1rem] tw-my-auto tw-h-[1.5rem] tw-w-[1.5rem] tw-cursor-pointer tw-appearance-none tw-opacity-80"
            onClick={() => {
              setIsRevealPassword(!isRevealPassword);
            }}
            type="button"
          >
            {isRevealPassword ? (
              <VisibilityIcon className="tw-h-full tw-w-full" />
            ) : (
              <VisibilityOffIcon className="tw-h-full tw-w-full" />
            )}
          </button>
        )}
      </div>
      {validationMessage && (
        <>
          <Spacer level={0.5} />
          <FormValidationMessage message={validationMessage} />
        </>
      )}
    </div>
  );
};
