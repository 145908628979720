import {
  AuthState,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import { appPublicConfig } from '@/configs/appPublicConfig';
import type { AmplifyAuthResponse } from '@/shared/hooks/useAmplifyAuth';
import { useAmplifyAuth } from '@/shared/hooks/useAmplifyAuth';
import { useUserInfo } from '@/shared/hooks/useUserInfo';
import type { ReadonlyURLSearchParams } from 'next/navigation';

type State = {
  pageStatus:
    | 'INIT'
    | 'INPUT'
    | 'COMPLETE'
    | 'NOTICE_PASSWORD_RESET_REQUIRED'
    | 'NOTICE_USER_NOT_CONFIRMED';
  errorMessage: string;
  formValues: {
    loginId: string;
    password: string;
  };
};

type Action =
  | {
      type: 'INITIALIZED';
    }
  | {
      type: 'API_CALLED';
      payload: {
        apiResponse: AmplifyAuthResponse;
      };
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZED': {
      return {
        ...state,
        pageStatus: 'INPUT',
      };
    }

    case 'API_CALLED': {
      const {
        apiResponse: { errorCode, errorMessage },
      } = action.payload;

      if (errorCode) {
        switch (errorCode) {
          case 'PasswordResetRequiredException': {
            return {
              ...state,
              pageStatus: 'NOTICE_PASSWORD_RESET_REQUIRED',
            };
          }

          case 'UserNotConfirmedException': {
            return {
              ...state,
              pageStatus: 'NOTICE_USER_NOT_CONFIRMED',
            };
          }

          default: {
            return {
              ...state,
              pageStatus: 'INPUT',
              errorMessage,
            };
          }
        }
      }

      return {
        ...state,
        pageStatus: 'COMPLETE',
        errorMessage: '',
      };
    }
  }
};

export type UseLoginForm = {
  state: State;
  isLoading: boolean;
  invokeLoginChallenge: (formValues: State['formValues']) => Promise<void>;
};

function getValidBackUrl(searchParams: ReadonlyURLSearchParams | null): string {
  const backUrl = searchParams?.get('back') ?? '';
  if (!backUrl) {
    return appPublicConfig.HELLOERO;
  }

  try {
    const isValid = new URL(backUrl).hostname.endsWith(
      appPublicConfig.COOKIE_DOMAIN
    );
    return isValid ? backUrl : appPublicConfig.HELLOERO;
  } catch {
    return appPublicConfig.HELLOERO;
  }
}

export function useLoginForm(): UseLoginForm {
  const router = useRouter();
  const searchParams = useSearchParams();
  const backUrl = getValidBackUrl(searchParams);
  const [state, dispatch] = React.useReducer(reducer, {
    pageStatus: 'INIT',
    errorMessage: '',
    formValues: {
      loginId: '',
      password: '',
    },
  });
  const { isLoadedUserInfo, userInfo } = useUserInfo();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { signIn } = useAmplifyAuth();

  const invokeLoginChallenge: UseLoginForm['invokeLoginChallenge'] =
    React.useCallback(
      async (formValues) => {
        if (isLoading) {
          return;
        }

        setIsLoading(true);

        const apiResponse = await signIn(formValues);

        dispatch({
          type: 'API_CALLED',
          payload: {
            apiResponse,
          },
        });

        setIsLoading(false);
      },
      [isLoading, signIn]
    );

  React.useEffect(() => {
    switch (state.pageStatus) {
      case 'INIT': {
        if (!isLoadedUserInfo) {
          return;
        }

        if (userInfo.isLoggedIn) {
          router.replace(backUrl);
          return;
        }

        dispatch({
          type: 'INITIALIZED',
        });
        return;
      }

      case 'COMPLETE': {
        if (!userInfo.isLoggedIn) {
          Hub.dispatch(UI_AUTH_CHANNEL, {
            event: AUTH_STATE_CHANGE_EVENT,
            message: AuthState.SignIn,
          });
          return;
        }

        // Hub.dispatch が完了し、Contextが更新されたら re-runされる
        router.replace(backUrl);
        return;
      }
    }
  }, [
    backUrl,
    isLoadedUserInfo,
    router,
    state.pageStatus,
    userInfo.isLoggedIn,
  ]);

  return {
    state,
    isLoading,
    invokeLoginChallenge,
  };
}
