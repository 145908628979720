'use client';
import React from 'react';

export const FormErrorMessage: React.FC<{
  message: string;
}> = ({ message }) => {
  React.useEffect(() => {
    if (!message) {
      return;
    }

    window.scrollTo(0, 0);
  }, [message]);

  return message ? (
    <div className="tw-relative tw-mb-[3rem] tw-p-[1rem]">
      <div className="tw-absolute tw-inset-0 tw-rounded-4 tw-bg-caution-lv1 tw-opacity-20" />
      <div className="tw-relative tw-text-base2 tw-font-bold tw-text-caution-lv1">
        {message}
      </div>
    </div>
  ) : null;
};
