'use client';
import { Loading } from '@/shared/components/parts/Loading';
import { NoticePage } from '@/shared/components/parts/NoticePage';
import { InputForm } from './InputForm';
import { useLoginForm } from './index.hook';

export const LoginForm: React.FC = () => {
  const { state, invokeLoginChallenge, isLoading } = useLoginForm();

  return (
    <>
      {(state.pageStatus === 'INIT' || state.pageStatus === 'COMPLETE') && (
        <Loading />
      )}
      {state.pageStatus === 'INPUT' && (
        <InputForm
          state={state}
          invokeLoginChallenge={invokeLoginChallenge}
          isLoading={isLoading}
        />
      )}
      {state.pageStatus === 'NOTICE_PASSWORD_RESET_REQUIRED' && (
        <NoticePage
          title="ログインできません。"
          texts="このアカウントはパスワードリセットされています。パスワードを再設定してください。"
          links={[
            {
              text: 'パスワードの変更',
              href: '/reset-password',
            },
          ]}
        />
      )}
      {state.pageStatus === 'NOTICE_USER_NOT_CONFIRMED' && (
        <NoticePage
          title="ログインできません。"
          texts={[
            'アカウント登録が完了していないため、ログインできません。',
            'メールアドレスの確認をしてアカウント登録を完了してください。',
          ]}
          links={[
            {
              text: 'メールアドレスの確認',
              href: '/resend-signup',
            },
          ]}
        />
      )}
    </>
  );
};
